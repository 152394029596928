@import '../../sass/mixins';
.SearchResult {
  &--spacing {
    margin: 5px 0;
  }

  &__sectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5 {
      line-height: 22px;
      margin: 0;
      padding: 0;
    }

    a {
      color: var(--primary);
      font-weight: 500;
      font-size: 14px;
    }
  }

  &__content {
    @include card;
    padding: 12px;

    h3 {
      margin: 0 0 5px;
    }
  }
}
