.AdditionalInformationTab {
  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 17px;
  }

  &__tag {
    display: flex;
    align-items: center;
    color: var(--primary);
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.24px;
    background-color: var(--primary-light);
    padding: 8px 5px 8px 12px;
    border-radius: 6px;
    gap: 3px;

    svg {
      max-width: 12px;
      path {
        fill: var(--primary);
      }
    }

    &RemoveButton {
      min-width: 18px !important;
      max-width: 18px !important;
      max-height: 18px !important;
      height: 18px !important;
      min-height: 18px !important;
      line-height: 18px !important;
    }
  }
}
