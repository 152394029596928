.UserCard {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 3px;
  padding: 7px;

  &__image {
    margin-right: 7px;
  }

  &__userNameWrapper {
    word-break: break-word;
    max-width: 80%;
  }
}
