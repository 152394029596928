.Header {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0px 12px;
  background: var(--primary-gradient);
  top: 0;
  left: 0;
  z-index: 999;

  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    height: var(--header-height);
    max-width: var(--wrapper-maxwidth);
  }

  &__logo {
    position: relative;
    width: 42px;
    left: 1px;
    top: 1px;

    @for $i from 1 through 3 {
      .star#{$i} {
        fill: #ffffff;
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    margin-left: 25px;

    &Link {
      margin-left: 7px;
      text-decoration: none;
      color: #ffffff;
      font-weight: 500;
      border-radius: 25px;
      padding: 5px 15px;
      transition: background-color 200ms ease-in-out;
      &:first-child {
        margin-left: 0;
      }

      &:hover {
        background-color: rgba(#000000, 0.08);
      }

      &--active {
        font-weight: 800;
        background-color: rgba(#000000, 0.19) !important;
      }
    }
  }

  &__title,
  &__menuButton button {
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: #ffffff !important;
  }

  &__rightMenu {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__search {
    position: relative;
  }
  &__searchResult {
    position: absolute;
    width: 1220px;
    top: 40px;
    right: 0;
  }

  &__menuButton {
    margin-left: 7px;
  }
}
