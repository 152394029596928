.BaseModal {
  &__title {
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
  }

  &__actionButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
  }
}
