.VideoRejectionReasonModal {
  &__error {
    color: #d32f2f;
    margin: 12px 0;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }
}
