@import '../../sass/mixins';

.SearchInput {
  $base: &;
  @include card;
  display: flex;
  align-items: center;
  padding: 0 16px;
  color: var(--dark);

  &--rounded {
    border-radius: 50px;
    overflow: hidden;
  }

  &--compact {
    box-shadow: none !important;

    #{$base}__input {
      height: 38px;
      line-height: 38px;
    }
  }

  &__input {
    flex: 1;
    margin-left: 14px;
    font-size: 17px;
    border: none;
    outline: none;
    height: 50px;
    line-height: 50px;
  }

  &__loader {
    width: 30px;
  }
}
