.AdminNote {
  $base: &;
  display: flex;
  margin-top: 15px;

  &:first-child {
    margin-top: 0;
  }

  &__deleteNote {
    visibility: hidden;
  }

  &:hover {
    #{$base}__deleteNote {
      visibility: visible;
    }
  }

  &__bubble {
    position: relative;
    padding: 12px;
    margin: 0 12px;
    background: #ffffff;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    max-width: 274px;
    border-radius: 3px;
    flex-grow: 1;
  }

  &__author {
    font-size: 14px;
    line-height: 14px;
    color: #757575;
    margin-bottom: 7px;
    font-weight: 600;
  }

  .MuiChip-root {
    //Chip Component
    background-color: var(--secondary) !important;
    color: #ffffff;
  }

  &__content {
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.15px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  &__time {
    font-size: 12px;
    line-height: 12px;
    color: #666666;
    text-align: center;
    padding: 5px 0;
  }
}
