.BookingRow {
  &__actions {
    display: flex;
  }

  &__videoRow {
    display: flex;
    cursor: pointer;

    p {
      padding: 0;
      margin: 0;
    }
  }

  &__video {
    margin-right: 12px;
    flex-shrink: 0;
  }

  &__cellRow {
    padding: 0;
    margin: 0;
  }

  &__userInfo {
    display: flex;
    align-items: center;
    text-align: left;
    cursor: pointer;
  }

  &__image {
    margin-right: 7px;
  }

  &__userFullName {
    word-wrap: break-word;
    max-width: 80%;
  }
}
