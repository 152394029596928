.CopyBtn {
  &__copy-button {
    padding: 10px 15px;
    background-color: transparent; /* Set the desired background color */
    border: none; /* Set the desired border */
    cursor: pointer;
    background-image: url('../../images/copy.svg');
    background-repeat: no-repeat;
    background-size: 24px 24px; /* Set the size of the SVG */
    background-position: center;
    /* Add any other styles as needed */
  }
}
