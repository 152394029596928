.Conversation {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  &__navigation {
    padding: 8px 16px;
    background: #9a43f7;
    color: #ffffff;

    path {
      //Modify IconButton svg directly
      fill: #ffffff;
    }
  }

  &__wrapper {
    flex: 1;
    height: 100%;
  }

  &__content {
    padding: 0 15px 15px;
  }

  &__dateRow {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__date {
    display: block;
    background: #7e7e7e;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.16px;
    color: #ffffff;
    border-radius: 16px;
    padding: 4px 12px;
    margin: 15px 0 0;
  }

  &__noMessages {
    color: var(--dark);
    margin-top: 150px;
    text-align: center;
  }
}
