.Mentions {
  &__link {
    color: var(--primary);
    text-decoration: none;

    &:hover {
      color: var(--primary);
    }
  }
}
