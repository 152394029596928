.TicketsTab {
  &__list {
  }

  &__row,
  &__header {
    display: flex;
  }

  &__header {
    padding: 12px;
    border-bottom: 1px solid #eee;
    font-weight: 500;
  }

  &__row {
    margin: 0 12px;
    padding: 7px 0;
    border-top: 1px solid #eee;

    &:first-child {
      border: none !important;
    }
  }

  &__ticketName {
    flex: 3 0;
  }

  &__ticketQuantity {
    flex: 1 0;
    text-align: right;
  }
}
