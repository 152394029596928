@import '../../sass/mixins';
.HomePageCms {
  &__content {
    @include card;
    margin-top: 19px;
  }

  &__heroBanner {
    &__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;
      position: relative;
      width: 100%;
      min-height: 310px;
      max-height: 600px;
      height: calc(310px + (600 - 310) * ((100vw - 640px) / (1391 - 640)));
    }

    &__loader {
      position: relative;
      object-fit: cover;
      object-position: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 600px;
      // height: 290px;

      width: 100%;
      height: 100%;
    }

    &__image {
      position: absolute;
      left: 26px;
      object-fit: cover;
      object-position: center;
      z-index: 1;
      min-height: 147px;
      max-height: 330px;
      height: calc(147px + (330 - 147) * ((100vw - 640px) / (1391 - 640)));
      min-width: 539px;
      max-width: 1212px;
      width: calc(539px + (1212 - 539) * ((100vw - 640px) / (1391 - 640)));
      top: 71px;
    }

    &__alertContainer {
      margin: 12px auto;
      width: 534px;
      height: 36px;
    }

    @media (max-width: 620px) {
      &__alertContainer {
        margin: 12px 23px;
      }
    }

    &__alertContent.MuiPaper-root {
      height: 100%;
      font-size: 12px;
      padding: 2px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    @media (min-width: 1391px) {
      &__image {
        top: 161px;
        left: 60px;
      }
    }

    @media (max-width: 640px) {
      &__image {
        top: 71px;
        left: 26px;
      }
    }

    @media (min-width: 640px) and (max-width: 1391px) {
      &__image {
        top: calc(71px + (161 - 71) * ((100vw - 640px) / (1391 - 640)));
        left: calc(26px + (60 - 26) * ((100vw - 640px) / (1391 - 640)));
      }
    }

    &__defaultImage {
      object-position: top;
      object-fit: cover;
      width: 100%;
      min-height: 265px;
      max-height: 544px;
      height: calc(265px + (544 - 265) * ((100vw - 640px) / (1391 - 640)));
    }
  }

  &__secondaryBanners {
    &__container {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      min-height: 310px;
      max-height: 600px;
      height: calc(310px + (600 - 310) * ((100vw - 640px) / (1391 - 640)));
    }

    &__defaultImage {
      width: 100%;
      min-width: 300px;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }

    &__alertContainer {
      margin: 12px auto;
      width: 534px;
      height: 36px;
    }

    @media (max-width: 620px) {
      &__alertContainer {
        margin: 12px 23px;
      }
    }

    &__alertContent.MuiPaper-root {
      height: 100%;
      font-size: 12px;
      padding: 2px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    @media (min-width: 900px) {
      &__defaultImage {
        padding-left: 12px;
      }
    }

    &__titles {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0px 12px;
    }

    &__image {
      position: absolute;
      top: 157px;
      left: 69px;
      object-fit: cover;
      object-position: center;
      z-index: 1;

      min-height: 110px;
      max-height: 330px;
      height: calc(110px + (330 - 110) * ((100vw - 640px) / (1391 - 640)));
      min-width: 471px;
      max-width: 1212px;
      width: calc(471px + (1212 - 471) * ((100vw - 640px) / (1391 - 640)));
    }

    @media (min-width: 626px) and (max-width: 899px) {
      &__image {
        top: calc(157px + (222 - 157) * ((100vw - 626px) / (899 - 626)));
        left: calc(69px + (93 - 69) * ((100vw - 626px) / (899 - 626)));
        width: calc(472px + (693 - 472) * ((100vw - 626px) / (899 - 626)));
        height: calc(110px + (162 - 110) * ((100vw - 626px) / (899 - 626)));
      }
    }

    @media (min-width: 900px) and (max-width: 972px) {
      &__image {
        top: calc(219px + (238 - 219) * ((100vw - 900px) / (972 - 900)));
        left: calc(104px + (110 - 104) * ((100vw - 900px) / (972 - 900)));
        width: calc(685px + (743 - 685) * ((100vw - 900px) / (972 - 900)));
        height: calc(160px + (173 - 160) * ((100vw - 900px) / (972 - 900)));
      }
    }

    @media (min-width: 973px) and (max-width: 1392px) {
      &__image {
        top: calc(161px + (228 - 161) * ((100vw - 973px) / (1392 - 973)));
        left: calc(82px + (107 - 82) * ((100vw - 973px) / (1392 - 973)));
        width: calc(488px + (713 - 488) * ((100vw - 973px) / (1392 - 973)));
        height: calc(114px + (166 - 114) * ((100vw - 973px) / (1392 - 973)));
      }
    }

    @media (min-width: 1393px) {
      &__image {
        top: 228.16px;
        left: 107px;
        max-width: 713.5px;
        max-height: 166.1px;
      }
    }
  }
}
