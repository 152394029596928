.NewUserModal {
  &__description {
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 10px;
  }

  &__datePicker {
    min-width: 150px;
    text-align: left;
    background-color: #fff;
    padding: 4px;
    border: none;
    border-bottom: 1px solid rgb(171, 171, 171);
    line-height: 19px;
    font-size: 15px;
    cursor: pointer;
  }

  &__expirationCalendar {
    position: relative;
    z-index: 99999;
  }
}
