.UserRow {
  &__cellRow {
    padding: 0;
    margin: 0;
  }

  &__capital {
    text-transform: capitalize;
  }
}
