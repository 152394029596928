.ReportCard {
  display: flex;
  background-color: #f5f5f5;
  border-radius: 3px;
  width: 100%;
  padding: 7px;

  &__video {
    flex-shrink: 0;
  }

  &__details {
    display: block;
    margin-left: 7px;
    width: 100%;
  }

  &__info {
    display: flex;
    align-items: center;
    padding: 1px 0;

    &--capitalize {
      text-transform: capitalize;
    }
  }

  &__image {
    margin-right: 7px;
  }

  &__userNameWrapper {
    display: block;
    word-break: break-word;
    max-width: 85%;
  }
}
