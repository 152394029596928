@import '../../sass/mixins';
.Statistic {
  &__globalStats {
    margin-bottom: 15px;
  }

  &__globalStatsContainer {
    display: flex;
    gap: 12px;
    margin-bottom: 15px;

    p {
      background-color: var(--primary);
      color: #fff;
      padding: 4px 0;
    }
  }

  &__filterControls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }

  &__dateRange {
    max-width: 450px;
  }

  &__content {
    @include card;
    margin-top: 19px;
  }
}
