.SearchModal {
  &__results {
    display: block;
    width: 100%;
    margin-top: 12px;
  }
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 3px;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  &__title {
    margin-left: 12px;
    margin-right: 12px;
    max-width: 250px;
    word-break: break-all;
  }

  &__action {
    margin-left: auto;
  }
}
