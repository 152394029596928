.BookingLogRow {
  &__actions {
    display: flex;
  }

  &__videoRow {
    display: flex;
    cursor: pointer;

    p {
      padding: 0;
      margin: 0;
    }
  }

  &__reservationId {
    color: var(--dark);
    margin-top: 7px;
  }

  &__video {
    margin-right: 12px;
    flex-shrink: 0;
  }

  &__cellRow {
    padding: 0;
    margin: 0;
  }

  &__userInfo {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }

  &__image {
    margin-right: 7px;
  }

  &__userNameWrapper {
    word-break: break-word;
    width: 100%;
  }

  &__userName {
    color: var(--dark) !important;
  }

  &__invoice {
    display: flex;
    align-items: center;
    gap: 7px;
    cursor: pointer;

    span {
      font-weight: 600;
    }
  }
}
