@import '../../sass/mixins';
.Banners {
  &__content {
    @include card;
    margin-top: 19px;
  }

  &__alertContainer {
    margin: 12px auto;
    width: 534px;
    height: 36px;
  }

  &__alertContent.MuiPaper-root {
    height: 100%;
    font-size: 12px;
    padding: 2px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
