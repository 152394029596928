.AddCommentsTab {
  &__mapContainer {
    width: 100%;
    height: 170px;
  }

  &__mapLink {
    display: flex;
    justify-content: center;
  }

  &__resolved {
    text-align: center;
    color: var(--grey-300);
    font-weight: bold;
    margin: 20px 0;
  }
}
