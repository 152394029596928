.UserRow {
  &__userInfo {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    cursor: pointer;
  }

  &__email {
    word-wrap: break-word;
    max-width: 90%;
  }

  &__image {
    margin-right: 7px;
  }

  &__userName {
    color: var(--dark) !important;
  }

  &__userNameWrapper {
    word-wrap: break-word;
    max-width: 80%;
  }

  &__cellRow {
    padding: 0;
    margin: 0;
  }

  &__capital {
    text-transform: capitalize;
  }
}
