.NewBannerModal {
  &__banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 6px;
    padding: 25px 17px;
    margin-bottom: 12px;
  }

  &__bannerImage {
    width: 100%;
    max-width: 436px;
    height: auto;
    margin-bottom: 12px;
  }

  &__imageError {
    color: #d32f2f;
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }

  &__formSpace {
    margin-bottom: 12px;
  }
}
