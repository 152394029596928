.ActionsToolbar {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: #323232;
  width: 100%;
  left: 0;
  top: var(--header-height);

  .MuiButton-root {
    color: #ffffff;
  }

  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    max-width: var(--wrapper-maxwidth);
  }

  &__selectedCount {
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 0.15px;
  }

  &__separator {
    display: block;
    width: 1px;
    margin: 10px 24px;
    align-self: stretch;
    background-color: #e0e0e0;
  }
}
