.VideoReportRow {
  &__userInfo {
    display: flex;
    align-items: center;
    text-align: left;
    cursor: pointer;
  }

  &__cellRow {
    padding: 0;
    margin: 0;
  }

  &__image {
    margin-right: 7px;
  }

  &__actions {
    display: flex;
  }

  &__userFullName {
    word-wrap: break-word;
    max-width: 80%;
  }

  &__reportReasons {
    margin-top: 12px;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }
}
