@import '../../sass/mixins';

.Users {
  &__filterControls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }

  &__search {
    margin-right: 7px;
    // width: 750px;
  }

  &__content {
    @include card;
    margin-top: 19px;
  }

  &__pageButtons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__loader {
    width: 32px;
  }
}
