.BannerRow {
  // &__reportReasons {
  //   margin-top: 12px;
  // }

  &__copy-button {
    vertical-align: bottom;
    display: inline-flex;
    padding: 6px 0px;
    justify-content: center;
    align-items: center;
    border: 1px solid #7741de57;
    border-radius: 4px;
    margin: 0 10px;
  }
}
