:root {
  --grey-100: #f2f3f6;
  --grey-200: #f2f2f2;
  --grey-300: #abb1c0;
  --primary: #7741de;
  --primary-50: #f9f5ff;
  --primary-100: #f2eaff;
  --primary-darker: #3c00ac;
  --primary-light: #cab0fc;
  --primary-lighter: #f9f5ff;
  --purple-800: #4a4c84;
  --purple-900: #303273;
  --dark: #585858;
  --secondary: #ea3ad3;
  --red: #ff6961;
  --blue: #22cdd8;
  --orange: #f8ae4c;
  --water: #31aade;
  --primary-gradient: linear-gradient(239.18deg, #b83af3 9.83%, #6950fb 105.7%);
  --header-height: 56px;
  --wrapper-maxwidth: 1350px;
  --panel-maxwidth: 440px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e5e5 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes slideInFromRight {
  from {
    right: calc(#{var(--panel-maxwidth)} * -1);
  }

  to {
    right: 0;
  }
}

.slideInFromRight {
  animation-name: slideInFromRight;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
}

@keyframes slideOutToRight {
  from {
    right: 0;
  }

  to {
    right: calc(#{var(--panel-maxwidth)} * -1);
  }
}

.slideOutToRight {
  animation-name: slideOutToRight;
  animation-duration: 50ms;
  animation-fill-mode: forwards;
}
