.StatisticRow {
  &__userInfo {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__showBookingList {
    margin-left: auto;
  }

  &__bookingList {
    border: none !important;
    background-color: #fafafa;
  }

  &__pointer {
    cursor: pointer;
  }

  &__image {
    margin-right: 7px;
  }

  &__userName {
    color: var(--dark) !important;
  }

  &__userNameWrapper,
  &__experienceNameWrapper {
    word-wrap: break-word;
    max-width: 80%;
  }
}
