.EditUserModal {
  &__description {
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 10px;
  }

  &__image {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
  }
}
