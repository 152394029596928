.PaymentRow {
  &__userInfo {
    display: flex;
    align-items: center;
  }

  &__image {
    margin-right: 7px;
  }

  &__userName {
    color: var(--dark) !important;
  }

  &__userNameWrapper {
    word-wrap: break-word;
    max-width: 80%;
  }
}
