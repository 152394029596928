@import '../../sass/mixins';

.ModerateComments {
  &__filterControls {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    @include card;
    margin-top: 19px;
  }

  &__pageButtons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__loader {
    width: 32px;
  }
}
