@import '../../sass/mixins';

.DateRangeInput {
  $base: &;
  @include card;
  padding: 0 16px;
  color: var(--dark);
  overflow: hidden;

  &__controls {
    display: flex;
    align-items: center;
    gap: 7px;
    height: 50px;
  }

  .react-datepicker-wrapper {
    flex: 1;
  }

  &__datePicker {
    width: 100%;
    text-align: left;
    padding: 4px;
    border: none;
    border-radius: 4px;
    background-color: #f5f5f5;
    border-bottom: 1px solid rgb(171, 171, 171);
    line-height: 19px;
    font-size: 15px;
    cursor: pointer;
    margin: 0px;
  }

  &__birthdayCalendar {
    position: relative;
    z-index: 99999;
  }
}
