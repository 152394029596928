.EditCategoryModal {
  align-items: center;

  &__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 200px;
    background-color: #f5f5f5;
    border-radius: 6px;
    padding: 25px 17px;
    margin-right: 12px;
    margin-bottom: 12px;
  }

  &__iconImage {
    width: 100%;
    max-width: 68px;
    max-height: 68px;
    margin-bottom: 12px;
    border: none;
  }

  &__imageError {
    color: #d32f2f;
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }
}
