.Video {
  position: relative;
  width: 70px;
  height: 110px;
  border-radius: 11px;
  overflow: hidden;

  &--medium {
    width: 110px;
    height: 193px;
  }

  &--large {
    width: 300px;
    height: 490px;
  }

  &__container {
    width: 100%;
    height: 100%;
  }

  video {
    object-fit: cover;
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 600ms;
    animation-fill-mode: forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}
