.EditEventPanel {
  &__content {
    display: flex;
    flex: 1;
  }

  &__action {
    padding: 12px;

    & > * {
      width: 100%;
    }
  }
}
