.MessagesRow {
  display: flex;
  align-items: center;
  margin-top: 2px;
  background-color: #ffffff;

  &:first-child {
    margin-top: 0;
  }

  &__button {
    justify-content: flex-start !important;
    width: 100%;
    padding: 8px 15px !important;
    border-radius: 0 !important;
  }

  &__buttonText {
    text-transform: none !important;
    font-weight: normal !important;
  }

  &__name {
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    margin-left: 16px;
    color: rgba(0, 0, 0, 0.87);
  }
}
