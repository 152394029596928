.Loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  &--adapt {
    height: 100%;
  }

  &--backdrop {
    background-color: rgba(0, 0, 0, 0.4);
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    position: relative;
    left: 1px;
    top: 1px;

    &--light {
      @for $i from 1 through 3 {
        .star#{$i} {
          fill: #ffffff;
        }
      }
    }
  }
}
