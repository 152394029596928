.PaymentDetailsModal {
  &__tabs {
    margin-bottom: 12px;
  }

  &__subheader {
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
  }

  &__message {
    font-size: 12px;
    line-height: 166%;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 10px;
  }

  &__payouts {
    margin: 5px 0;
    padding: 10px 0;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
  }

  &__transferItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 1px;
  }

  &__ticketList {
    margin-left: 25px;
  }

  &__ticket {
    display: flex;
    color: #909090;

    &Name {
      flex: 4;
    }

    &Quantity {
      flex: 1;
    }

    &Amount {
      flex: 1;
      text-align: right;
    }
  }

  &__amount--fee {
    color: var(--primary);
  }

  &__payoutDescription {
    color: #444;
    font-size: 13px;
  }

  &__total {
    font-size: 17px;
    font-weight: 400;
  }
}
