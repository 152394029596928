.SignIn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: linear-gradient(239.18deg, #b83af3 9.83%, #6950fb 105.7%);
  padding: 12px;

  &__logo {
    margin-bottom: 20px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 43px;
    width: 100%;
    max-width: 486px;
    &Wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .MuiInput-input {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
      box-shadow: 0 0 0px 1000px #fff inset;
    }
  }
}
