.BasePanel {
  display: block;
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: var(--panel-maxwidth);
  right: calc(var(--panel-maxwidth) * -1);
  background-color: #ffffff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);

  $panelHeaderHeight: 55px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    height: 55px;
    border-bottom: 1px solid #e0e0e0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--header-height) - #{$panelHeaderHeight});
    max-height: calc(100vh - var(--header-height) - #{$panelHeaderHeight});
  }

  &__title {
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
  }

  &__loader {
    height: 50vh !important;
  }
}
