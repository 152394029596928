@import '../../sass/mixins';
.BookingLogs {
  &__filterControls {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__pageButtons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__content {
    @include card;
    margin-top: 19px;
  }
}
