@import '../../sass/mixins';

.InvitationCode {
  &__content {
    @include card;
    margin-top: 19px;
  }

  &__loader {
    width: 32px;
  }
}
